<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.png" alt="" height="22" width="10">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>
        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/lego.jpg" alt=""width="50" height="50" style="
              margin-left: -12px;
              border-radius: 50px 50px 50px 50px;">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo.png" alt="" width="100px" height="100px">
          </span>
        </a>
      </div>
      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>
    <div class="d-flex">
      <div class="row justify-content-end">
        <div class="notifi-btn">
          <button type="submit" class="btn-sm btn-primary" (click)="openNotifications(mymodal)"> Notifications</button>
        </div>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/users/user.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1 label full-name">{{ full_name }}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item text-primary label" href="javascript: void(0);" (click)="changePassword()">
            <i class="mdi mdi-account-key font-size-16 align-middle me-1 text-primary"></i>
            {{ 'Change Password' | translate}}</a>
            <a class="dropdown-item text-danger label" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- model content -->

<ng-template #mymodal let-modal dialog>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Notification </h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')">
      <span class="close-text">X</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="row">
          <div class="form-group">
            <label class="form-control-label">Title</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="title"  />
          </div>
          <br>
          <div class="form-group">
            <label class="form-control-label">Body</label>
            <textarea class="form-control form-control-sm" rows="3" [(ngModel)]="body"></textarea>
          </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-sm btn-primary" (click)="send()">
      Send
    </button>
  </div>
</ng-template>
