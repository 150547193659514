import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true
  },
  {
    id: 3,
    label: 'Challenges',
    icon: 'bx-tone',
    subItems: [

      {
        id: 3,
        label: 'Create New',
        link: 'challenge/create',
        parentId: 3
      },
      {
        id: 2,
        label: 'Approved Challenge List',
        link: 'challenges/list',
        parentId: 3
      },
      {
        id: 4,
        label: 'Pending Approval List',
        link: 'corporate/list/pending',
        parentId: 3
      },

    ]
  },
  {
    id: 2,
    label: 'Article',
    icon: 'bx-file',
    subItems: [
      {
        id: 13,
        label: 'Create New',
        link: 'article/create',
        parentId: 2
      },
      {
        id: 18,
        label: 'Approved Article List',
        link: 'article/list',
        parentId: 2
      },
      {
        id: 99,
        label: 'Pending Article List',
        link: 'article/pending',
        parentId: 2
      },
    ]
  },

  {
    id: 6,
    label: 'Offers',
    icon: 'bx-briefcase-alt-2',
    subItems: [
      {
        id: 1,
        label: 'Create New',
        link: 'offer/create',
        parentId: 6
      },
      {
        id: 2,
        label: 'Approved Offers List',
        link: 'offers/list',
        parentId: 6
      },
      {
        id: 22,
        label: 'Pending Offers List',
        link: 'offers/pending',
        parentId: 6
      },
    ]
  },
  {
    id: 4,
    label: 'Users',
    icon: 'bx-user-circle',
    subItems: [
      {
        id: 1,
        label: 'Create',
        link: 'user/create',
        parentId: 4
      },
      {
        id: 2,
        label: 'List',
        link: 'user/list',
        parentId: 4
      },
    ]
  },

];

