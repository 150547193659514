import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { CurdService } from 'src/app/core/services/curd.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  full_name: any;
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  closeResult: string;
  user_id: string;
  all_users: any[];
  fcm_tokens: any[];
  title='';
  body='';

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router, private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    private curd: CurdService,
    private modalService: NgbModal,
    private auth: AuthenticationService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private fromBuilder: FormBuilder,
    private toast: ToastService,
    private http: HttpClient,
  ) {
  }

  push_form = this.fromBuilder.group({
    title:new FormControl('', [
      Validators.required,
    ]),
    body:new FormControl('', [
      Validators.required,
    ]),
  });
  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.getUser();
    this.collectFcmTokens();
    this.openMobileMenu = false;
    this.element = document.documentElement;
  }

  getUser() {
    this.auth.getUser().subscribe(res => {
      if (res) {
        this.user_id = res.uid
        this.curd.show('system_users', this.user_id).get().subscribe(user => {
          let user_info = user.data();
          // @ts-ignore
          this.full_name = user_info.displayName
          // @ts-ignore
        })
      }
    })
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }

  changePassword() {
    this.router.navigate(['/account/reset-password']);

  }

  openNotifications(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  collectFcmTokens() {
    this.auth.getUser().subscribe(user => {
      this.curd.query('users', ref => ref.where('corporate_id', '==', user.uid)).subscribe(res => {
        this.all_users = res;
        this.fcm_tokens = [];
        for (let user of this.all_users) {
          if (user.fcm_token) {
            this.fcm_tokens.push(user.fcm_token);
          }
        }
      })
    })
  }

  // send notifications
  send() {
    if (this.title!=''&& this.body!='') {
      if (this.fcm_tokens.length == 0) {
        this.toast.showError('Something Went Wrong! Try Again')
      }
      else if (this.fcm_tokens[0] == 'undefined') {
        this.toast.showError('Something Went Wrong! Try Again')
      }
      else {
        const body = {
          fcm_tokens:this.fcm_tokens,
          title: this.title,
          body: this.body
        }
        const headers = new HttpHeaders({
          'Content-Type': 'application/json'
        })
        this.http.post(`${environment.cf_base}/sendBulkPush`, body, { headers: headers }).subscribe(res => {
          if (res) {
              this.toast.customSuccess('Notification Sent Successfully');
              let form_values = {
                title:this.title,
                body: this.body,
               corporate_id : this.user_id,
               status : 'pending'
              }

              this.curd.store('notifications', form_values).then(res => {
              })
             this.title='',
              this.body=''
          }

          this.modalService.dismissAll();
        });
      }
    }
    else {
      this.toast.showError('Please Fill All Fields');
    }
  }
}
