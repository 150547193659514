import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import {  NgZone } from '@angular/core';
// import { User } from '../interfaces/user';
import firebase from 'firebase/app';
import auth from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { CurdService } from './curd.service';
import {environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;
    userData: any; // Save logged in user data
    constructor( public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private curd: CurdService,
    private http: HttpClient,) {
      /* Saving user data in localstorage when
      logged in and setting up null when logged out */
      this.afAuth.authState.subscribe(user => {
        if (user) {
          this.userData = user;
          localStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem('user'));
        } else {
          localStorage.setItem('user', null);
          JSON.parse(localStorage.getItem('user'));
        }
      });
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }
     /* Saving user data in localstorage when
    logged in and setting up null when logged out */

  private  config = {
    apiKey: 'AIzaSyBJWD3_7vSAjAZ6k0-zK50Sh7I2Yv7NKU8',
    authDomain: 'fitlego-e1026.firebaseapp.com',
    databaseURL: 'https://fitlego-e1026-default-rtdb.firebaseio.com'
  };
  private  secondaryApp = firebase.initializeApp(this.config, "Secondary");

  getUser() {
    return this.afAuth.authState;
  }

  autoLogin(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.curd.show('system_users', result.user['uid']).get().subscribe(res => {
            if (res.data()['user_type'] == "corporate" && res.data()['current_status']!="disable") {
              this.router.navigate(['dashboard']);
            } else {
              this.SignOut().then(() => {
                this.router.navigate(['login']);
              });
            }
          });
        });
        // this.SetUserData(result.user).then(r => {});
      }).catch((error) => {
        window.alert(error.message);
      });
  }

  regUser(value: any) {
    return new Promise<any>((resolve, reject) => {
    this.secondaryApp.auth().createUserWithEmailAndPassword(value.email, value.password.toString())
    .then(
      res => resolve(res),
      err => reject(err)
      )
      this.secondaryApp.auth().signOut();
    })
  }

  registerUser(value: any) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(value.email, value.password)
        .then(
          res => resolve(res),
          err => reject(err)

          )
    })

  }

  deleteUser(email){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const data={
      email: email
    }
    return this.http.post(`${environment.cf_base}/deleteUser`,data ,{headers: headers}).subscribe(res=>{
    });
  }
  // Sign up with email/password
  // SignUp(email, password) {
  //   return this.afAuth.createUserWithEmailAndPassword(email, password)
  //     .then((result) => {
  //       /* Call the SendVerificaitonMail() function when new user sign
  //       up and returns promise */
  //       this.SendVerificationMail();
  //       this.SetUserData(result.user);
  //     }).catch((error) => {
  //       window.alert(error.message);
  //     });
  // }

  // Send email verfificaiton when new user sign up
  // SendVerificationMail() {
  //   return this.afAuth.currentUser.sendEmailVerification()
  //     .then(() => {
  //       // this.router.navigate(['verify-email-address']);
  //     });
  // }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false);
  }

  // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  // Auth logic to run auth providers
  // AuthLogin(provider) {
  //   return this.afAuth.auth.signInWithPopup(provider)
  //     .then((result) => {
  //       this.ngZone.run(() => {
  //         this.router.navigate(['dashboard']);
  //       });
  //       this.SetUserData(result.user);
  //     }).catch((error) => {
  //       window.alert(error);
  //     });
  // }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  // SetUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`system_users/${user.uid}`);
  //   const userData: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     emailVerified: user.emailVerified
  //   };
  //   return userRef.set(userData, {
  //     merge: true
  //   });
  // }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']).then(r => { });
    });
  }
}

