<footer class="footer">
    <div class="container-fluid ">
        <div class="row">
            <div class="col-sm-6 label">
                {{year}} © Fitlygo.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block label">
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
</footer>
