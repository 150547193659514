import { Injectable } from '@angular/core';
import {FirestoreService} from "./firestore.service";

@Injectable({
  providedIn: 'root'
})
export class CurdService {
  constructor(private firestoreService: FirestoreService) { }

  index(collection_name: string) {
    return this.firestoreService.colWithIds$(collection_name);
  }

  query(collection_name: string, query: any) {
    return this.firestoreService.colWithIds$(collection_name, query);
  }

  filter(collection_name: string, filter_by: string, query) {
    const ref = ref => ref.where(filter_by, '>=', query).where(filter_by, '<=', query);
    return this.firestoreService.colWithIds$(collection_name, ref => ref.where(filter_by, '>=', query).where(filter_by, '<=', query+ '\uf8ff'));
  }

  store(collection_name: string, data: any) {
    return this.firestoreService.add(collection_name, data);
  }

  show(collection_name: string, document_id: any) {
    return this.firestoreService.doc(`${collection_name}/${document_id}`);
  }

  showRealTime(collection_name: string, document_id: any) {
    return this.firestoreService.doc$(`${collection_name}/${document_id}`);
  }

  update(collection_name: string, document_id: string, data: any) {
    return this.firestoreService.update(`${collection_name}/${document_id}`, data)
  }

  delete(collection_name: string, document_id: string) {
    return this.firestoreService.delete(`${collection_name}/${document_id}`);
  }

  // tslint:disable-next-line:typedef
  setWithID(collection_name: string, data: any) {
    return this.firestoreService.set(collection_name, data);
  }

  uploadImage(image: string, file_name: string, path: string, is_default: boolean = false) {
    return this.firestoreService.uploadImage(image, file_name, path, is_default);
  }

  uploadImageWithoutPath(image: string, file_name: string, folder: string) {
    return this.firestoreService.uploadImage(image, file_name, folder);
  }
}

